<template>
  <div class="exam-submit" v-loading="loading">
    <Header></Header>
    <article class="exam-submit-container">
      <section class="exam-content">
        <div class="exam-content-nav">
          <h2 class="nav-title">考试内容</h2>
          <router-link :to="{name: 'assignExam'}" replace class="continue-btn">
            <div>继续添加试题</div>
            <div class="icon"></div>
          </router-link>
        </div>
        <div class="exam-content-list-view">
          <div class="list-item" v-for="item in $store.state.examWorkList" :key="item.id">
            <div class="list-content">
              <div class="left">
                <span class="module">{{item.selectType ? '专用试题' : '通用试题'}}</span>
                <span class="name">{{item.name}}</span>
              </div>
              <div class="right">
                <div class="del-btn" @click="deleteWork(item.id)"></div>
              </div>
            </div>
          </div>
          <div class="statistics-view">
            <div>预计耗时：20~30分钟</div>
            <div>注意：当前选择试题若超过1套，试题将随机发放。</div>
          </div>
        </div>
      </section>
      <section class="exam-settings">
        <h2 class="nav-title" style="margin-bottom: 28px;">考试设置</h2>
        <div class="exam-settings-step">
          <div class="exam-settings-step__head">
            <div class="exam-settings-step__icon">1</div>
            <div class="exam-settings-step__line"></div>
          </div>
          <div class="exam-settings-step__main">
            <div class="exam-settings-step__title">编辑考试名称</div>
            <div class="exam-settings-step__content">
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">考试名称：</div>
                <div class="exam-settings-step__content__input">
                  <el-input v-model="name" placeholder="请输入本次作业名称" size="medium" maxlength="30"
                            style="width: 100%;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exam-settings-step">
          <div class="exam-settings-step__head">
            <div class="exam-settings-step__icon">2</div>
            <div class="exam-settings-step__line"></div>
          </div>
          <div class="exam-settings-step__main">
            <div class="exam-settings-step__title">选择学生</div>
            <div class="exam-settings-step__content">
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">考试类型：</div>
                <div class="exam-settings-step__content__radio">
                  <el-radio-group v-model="examType" size="medium">
                    <el-radio-button label="unify">统一考试</el-radio-button>
                    <el-radio-button label="again">
                      <span class="space"/>补考<span class="space"/>
                    </el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">选择学校：</div>
                <div class="exam-settings-step__content__search">
                  <el-autocomplete
                      size="medium"
                      v-model="searchName"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入学校名称搜索"
                      style="width: 473px;"
                      :trigger-on-focus="false"
                      @select="selectSchool"
                  ></el-autocomplete>
                  <el-select v-model="searchGradeId" size="medium"
                             placeholder="年级" style="width: 140px;margin-left: 14px;">
                    <el-option v-for="(item, index) in searchGradeList" :key="index"
                               :label="item.gradeName"
                               :value="item.gradeId"></el-option>
                  </el-select>
                  <el-button type="primary" @click="getOtherClasses" size="medium"
                             style="width: 100px;margin-left: 14px;">查询
                  </el-button>
                </div>
              </div>
              <div class="exam-settings-step__content__set exam-settings-step__content__set-expand">
                <div class="exam-settings-step__content__label exam-settings-step__content__label-expand">
                  选择班级：
                </div>
                <div class="exam-settings-step__content__classes">
                  <div class="exam-settings-step__content__classes-desc">
                    <div>
                      当前：
                      <template v-if="examType==='unify'">{{unifySearchDes}}</template>
                      <template v-else-if="examType==='again'">{{againSearchDes}}</template>
                    </div>
                    <template v-if="examType==='unify'&&unifyClassList.length">
                      <el-checkbox v-model="unifyClassesSelect" size="medium"
                                   @change="unifyClassSelectSwitch">
                        {{unifyClassesSelect?'取消全选':'点击全选'}}
                      </el-checkbox>
                    </template>
                    <template v-else-if="examType==='again'&&againClassList.length">
                      <div style="display: flex;align-items: center;cursor: pointer;"
                           @click="checkStudent">
                        <img width="20px" height="20px" src="../../assets/icon-watch.png"
                             alt="watch"/>
                        <span style="text-decoration: underline;margin-left: 10px;">查看已选学生</span>
                      </div>
                    </template>
                  </div>
                  <div class="exam-settings-step__content__classes-content" v-if="examType==='unify'">
                    <div v-for="(item, index) in unifyClassList"
                         :key="item.id"
                         :class="['classes-unify-list', item.active ? 'classes-unify-active' : '']"
                         @click="unifyClassSelect(index)">
                      {{item.className}}
                    </div>
                  </div>
                  <div class="exam-settings-step__content__classes-content"
                       v-else-if="examType==='again'">
                    <div v-for="(item, index) in againClassList"
                         :key="item.id"
                         :class="['classes-again-list', item.studentList.length ? 'classes-again-active' : '']"
                         @click="againClassSelect(index)">
                      <div class="classes-again-list__left">
                        <div class="text-ellipsis2">{{item.className}}</div>
                        <span class="classes-again-list__result">已选：{{item.studentList.length}}人</span>
                      </div>
                      <div class="classes-again-list__arrow-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exam-settings-step">
          <div class="exam-settings-step__head">
            <div class="exam-settings-step__icon">3</div>
            <div class="exam-settings-step__line"></div>
          </div>
          <div class="exam-settings-step__main">
            <div class="exam-settings-step__title">设置考试条件</div>
            <div class="exam-settings-step__content">
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">开始时间：</div>
                <el-date-picker
                    v-model="dateStart"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"></el-date-picker>
              </div>
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">结束时间：</div>
                <el-date-picker
                    v-model="dateEnd"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"></el-date-picker>
              </div>
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label__medium">请选择是否公布答案：</div>
                <div class="exam-settings-step__content__select">
                  <el-select class="search-year" v-model="toPublic" placeholder="状态" size="medium"
                             style="width: 270px;" @change="toPublicChange">
                    <el-option label="结束后立即公布" value="1"></el-option>
                    <el-option label="自定义公布时间" value="3"></el-option>
                    <el-option label="暂不公布" value="2"></el-option>
                  </el-select>
                </div>
                <el-popover trigger="hover" width="388">
                  <div class="pre-line">1.结束后立即公布：考试结束后，考生即可查看分数以及答案。

                    2.自定义公布时间：您可自行设定分数以及答案的公布时间。

                    3.暂不公布：考试结束后，暂不公布分数以及答案，需要您在考后再自行设定公布时间。

                  </div>
                  <div class="exam-settings-step__content__select__mark" slot="reference"></div>
                </el-popover>
              </div>
              <div class="exam-settings-step__content__set" v-show="toPublic === '3'">
                <div class="exam-settings-step__content__label__medium">自定义公布时间：</div>
                <div class="exam-settings-step__content__select">
                  <el-date-picker
                      v-model="publicTime"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                      :clearable="false"></el-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exam-settings-step">
          <div class="exam-settings-step__head">
            <div class="exam-settings-step__icon">4</div>
            <div class="exam-settings-step__line"></div>
          </div>
          <div class="exam-settings-step__main">
            <div class="exam-settings-step__title">考试留言</div>
            <div class="exam-settings-step__content">
              <div class="exam-settings-step__content__set">
                <div class="exam-settings-step__content__label">留给家长：</div>
                <div class="exam-settings-step__content__input">
                  <el-input v-model="teacherNotifyMsg" placeholder="对于此考试，您可给家长留言，字数在50字以内。（选填）"
                            size="medium" maxlength="50" show-word-limit style="width: 100%;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <footer class="footer">
      <div class="submit-btn" @click="submit">确认布置</div>
    </footer>

    <StudentDialog
        ref="studentDialog"
        :dialogVisible="dialogVisible"
        @handleVisible="dialogVisibleChange"
        @confirmSelected="confirmSelected"
    ></StudentDialog>

    <pointsModal
        ref="pointsModal"
        @confirmCallback="confirmCallback"
    ></pointsModal>
  </div>
</template>

<script>
  import Header from '@/layout/components/header.vue'
  import StudentDialog from './components/StudentDialog.vue'
  import pointsModal from '@/components/PointsModal.vue'
  import _ from 'lodash'
  import {getEnrollmentYears} from "@/api/classes";

  export default {
    name: 'examWorkSubmit',
    components: {
      Header,
      StudentDialog,
      pointsModal
    },
    data() {
      return {
        loading: false,
        name: '',
        teacherNotifyMsg: '', // 教师留言
        examType: 'unify', // unify: 统一考试, again: 补考
        list: {},
        dateStart: null,
        dateEnd: null,
        toPublic: '1',
        publicTime: null,
        searchName: '',
        searchId: '',
        gradeArr: ['六年级', '七年级', '八年级', '九年级', '高一', '高二', '高三'],
        searchGradeList: [],
        searchGradeId: '',
        searchYearList: [],
        classIndex: null,
        schoolList: [],
        unifySearchDes: '', // 统一考试搜索描述
        againSearchDes: '', // 补考搜索描述
        unifyClassesSelect: true, // 默认全选班级
        unifyClassList: [],
        againClassList: [],
        dialogVisible: false,
        schoolSection: null,
        yearSystem: null
      }
    },
    watch: {
      searchName(val) {
        const {schoolList} = this
        for (let i = 0, len = schoolList.length; i < len; i += 1) {
          if (val === schoolList[i].schoolName) {
            this.searchId = schoolList[i].schoolId
            break
          }
        }
      },
      searchId(val) {
        this.getEnrollmentYears()
        // this.getGradeBySchoolId(val)
      }
    },
    created() {
      this.superAdmin = sessionStorage.getItem('superAdmin')
      this.list = this.$store.state.examWorkList
      const date = new Date()
      this.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm', date)
      const nextWeek = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000 - (date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds()) * 1000 + 8 * 60 * 60 * 1000)
      this.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', nextWeek)
      const arr = Object.keys(this.list)
      if (arr.length === 1) {
        this.name = this.list[arr[0]].name
      } else {
        const nameDateArr = this.dateStart.split(' ')
        this.name = `模考作业${nameDateArr[0]}`
      }
      this.getSchoolList()
    },
    mounted() {
      window.addEventListener('beforeunload', this.beforeUnloadListener, {capture: true});
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeUnloadListener, {capture: true});
    },
    methods: {
      beforeUnloadListener(event) {
        return event.returnValue = 'Are you sure you want to exit?'
      },
      toPublicChange(val) {
        if (val === '3') {
          this.publicTime = this.dateEnd
        }
      },
      getGradeBySchoolId(val) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.getGradeBySchoolId,
          data: {
            schoolId: val
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            const searchYearList = []
            for (let i = 0, len = data.length; i < len; i += 1) {
              if (!searchYearList.includes(data[i].year)) {
                searchYearList.push(data[i].year)
              }
            }
            searchYearList.sort()
            this.searchYearList = searchYearList
          }
        }).catch(() => {
          this.loading = false
        })
      },
      // 选择学校
      selectSchool(val) {
        this.schoolSection = val.section
        this.yearSystem = val.yearSystem
        this.searchGradeId = ''
      },
      // 获取入学年份/年级
      async getEnrollmentYears() {
        this.searchGradeList = []
        try {
          this.loading = true
          const data = {
            section: this.schoolSection,
            yearSystem: this.yearSystem
          }
          const res = await getEnrollmentYears(data)
          this.loading = false
          if (res.state === '11') {
            res.data.sort((a, b) => {
              return a.gradeId - b.gradeId
            })
            this.searchGradeList = res.data
          }
        } catch (e) {
          this.loading = false
        }
      },
      getOtherClasses() {
        const dataParam = {}
        const {searchGradeId} = this
        const {searchId} = this
        if (searchId === '') {
          this.$alert('请选择学校', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        dataParam.schoolId = searchId
        dataParam.gradeTypeId = this.searchGradeId
        if (searchGradeId === '') {
          this.$alert('请选择年级', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        if (this.examType === 'unify') {
          this.unifySearchDes = `${this.searchName}，${this.gradeArr[this.searchGradeId]}`
        } else if (this.examType === 'again') {
          this.againSearchDes = `${this.searchName}，${this.gradeArr[this.searchGradeId]}`
        }
        this.classIndex = null
        this.$axios({
          method: 'post',
          url: this.$urls.getClazzForSuperExam,
          data: dataParam
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            for (let i = data.length - 1; i >= 0; i -= 1) {
              data[i].studentList = []
              if (searchGradeId !== data[i].gradeTypeId) {
                data.splice(i, 1)
              }
            }

            if (this.examType === 'unify') {
              this.unifyClassList = JSON.parse(JSON.stringify(data))
              this.unifyClassSelectSwitch(this.unifyClassesSelect = true)
            } else if (this.examType === 'again') {
              this.againClassList = JSON.parse(JSON.stringify(data))
            }
          }
        }).catch(() => {
          this.loading = false
        })
      },
      querySearch(queryString, cb) {
        const {schoolList} = this
        const list = schoolList.filter(this.schoolFilter(queryString))
        cb(list)
      },
      schoolFilter(name) {
        return (school) => {
          return (school.schoolName.indexOf(name) !== -1)
        }
      },
      // 获取学校列表
      getSchoolList() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.getSchoolList
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            for (let i = 0, len = data.length; i < len; i += 1) {
              data[i].value = data[i].schoolName
            }
            this.schoolList = data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      getUsersByClassId() {
        this.loading = true
        const {againClassList} = this
        const {classIndex} = this
        const {classId: clazzId} = againClassList[classIndex]
        this.$axios({
          method: 'post',
          url: this.$urls.getUsersByClassId,
          data: {
            clazzId
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            const {studentList} = againClassList[classIndex]
            if (studentList.length) {
              for (let i = 0, len = data.length; i < len; i += 1) {
                for (let j = 0, len = studentList.length; j < len; j += 1) {
                  if (data[i].id === studentList[j].id) {
                    data[i].selected = studentList[j].selected
                    break
                  }
                }
              }
            }
            let list = JSON.parse(JSON.stringify(againClassList[classIndex]))
            list.studentList = data
            this.$refs.studentDialog.getList([list])
          }
        }).catch(() => {
          this.loading = false
        })
      },
      /**
       * 全选 / 全不选
       * @param val
       */
      unifyClassSelectSwitch(val) {
        if (this.unifyClassList.length) {
          this.unifyClassList = this.unifyClassList.map(item => {
            item.active = val
            return item
          })
        }
      },
      /**
       * 选中某一班级
       * @param index
       */
      unifyClassSelect(index) {
        const {active} = this.unifyClassList[index]
        this.unifyClassList[index].active = !active
        this.unifyClassList = JSON.parse(JSON.stringify(this.unifyClassList))
        // 是否全选中
        this.unifyClassesSelect = this.unifyClassList.every(item => item.active === true)
      },
      /**
       * 选择班级中的学生
       * @param index
       */
      againClassSelect(index) {
        this.classIndex = index
        this.getUsersByClassId()
      },
      checkStudent() {
        const againClassList = JSON.parse(JSON.stringify(this.againClassList))
        if (againClassList.length > 0) {
          this.$refs.studentDialog.getList(againClassList)
        }
      },
      dialogVisibleChange() {
        this.dialogVisible = !this.dialogVisible
      },
      confirmSelected(list) {
        this.dialogVisible = false
        if (list.length === 1) {
          this.againClassList[this.classIndex] = list[0]
          this.againClassList = JSON.parse(JSON.stringify(this.againClassList))
        } else {
          this.againClassList = list
        }
      },
      deleteWork(id) {
        const obj = {
          id,
          exam: true
        }
        this.$store.commit('listDel', obj)
        const {list} = this
        delete list[obj.id]
        if (Object.keys(this.$store.state.examWorkList).length === 0) this.$router.back()
      },
      submit: _.debounce(function () {
        const {name} = this
        if (name === '') {
          this.$alert('请输入考试名称', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        if (this.searchName === '') {
          this.$alert('请选择学校', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        let dateStart = this.common.binarySystem(this.dateStart)
        dateStart = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateStart))
        let dateEnd = this.common.binarySystem(this.dateEnd)
        dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
        const startTime = new Date(dateStart).getTime()
        const endTime = new Date(dateEnd).getTime()
        const now = new Date().getTime()
        if (now >= endTime) {
          this.$alert('截止时间应大于当前时间', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        if (startTime >= endTime) {
          this.$alert('截止时间应大于开始时间', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const status = startTime > now ? 0 : 1
        const {searchGradeId: gradeTypeId} = this
        const {searchId: schoolId} = this
        const {againClassList} = this
        let clazzIds = []
        let userList = []
        if (this.examType === 'unify') { // 统一考试
          this.unifyClassList.forEach(item => {
            if (item.active) {
              clazzIds.push(item.classId)
            }
          })
        } else if (this.examType === 'again') { // 补考
          for (let i = 0, len = againClassList.length; i < len; i += 1) {
            const {studentList} = againClassList[i]
            if (studentList.length > 0) {
              const obj = {}
              obj.clazzId = againClassList[i].classId
              const arr = []
              for (let j = 0, len = studentList.length; j < len; j += 1) {
                arr.push(studentList[j].id)
              }
              obj.userIds = arr.join(',')
              userList.push(obj)
            }
          }
          if (userList.length === 0) {
            this.$alert('请选择学生', '提示', {
              confirmButtonText: '确定'
            })
            return
          }
        }
        const {list} = this
        const listArr = Object.keys(list)
        const itemObj = {}
        itemObj.paperQuestionIds = listArr.join(',')
        itemObj.type = '在线模考'
        itemObj.name = name
        itemObj.flag = list[listArr[0]].type === 2 ? 4 : 5
        const {toPublic} = this
        itemObj.toPublic = toPublic === '2' ? 2 : 1
        if (toPublic === '3') {
          itemObj.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(this.common.binarySystem(this.publicTime)))
        } else if (toPublic === '1') {
          itemObj.publicTime = dateEnd
        }
        const itemList = []
        itemList.push(itemObj)
        if (itemList.length === 0) {
          this.$alert('请选择试题', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        this.loading = true

        let dataParams = {
          name,
          dateStart,
          dateEnd,
          gradeTypeId,
          schoolId,
          itemList,
          teacherNotifyMsg: this.teacherNotifyMsg
        }
        let url = ''
        if (this.examType === 'unify') {
          Object.assign(dataParams, {
            clazzIds: clazzIds.join(',')
          })
          url = this.$urls.superExamSaveUnify
        } else if (this.examType === 'again') {
          Object.assign(dataParams, {
            status,
            userList
          })
          url = this.$urls.superExamSaveAgain
        }

        this.$axios({
          method: 'post',
          url,
          headers: {
            'Content-Type': 'application/json'
          },
          data: dataParams
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.$store.commit('listClear', 'exam')

            // 积分弹窗
            const {data} = response
            if (data && data.rewardPointsAddSuccess) {
              this.$refs.pointsModal.visibleChange({
                rewardPoints: data.rewardPoints,
                rewardPointsMsg: data.rewardPointsMsg
              })
            } else {
              this.$message({
                message: '布置考试成功！',
                type: 'success'
              })
              this.$router.push({
                name: 'checkExam'
              })
            }

          }
        }).catch(() => {
          this.loading = false
        })
      }, 1000, {leading: true, trailing: false}),
      confirmCallback() {
        this.$router.push({
          name: 'checkExam'
        })
      }
    }
  }
</script>

<style lang="scss">
  .exam-submit {
    .el-date-editor--datetime {
      width: 120px;
      font-size: 15px;

      .el-input__inner {
        border: none;
        padding: 0;
        text-decoration: underline;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }

  .exam-settings-step__content__input {
    width: 100%;

    .el-input__inner {
      height: 44px;
      line-height: 44px;
      color: #333333;
      font-size: 17px;
    }
  }

  .exam-settings-step__content__radio {
    .el-radio-button__inner {
      font-size: 15px;
      font-weight: normal;
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #309AF2;
      font-weight: bold;
      background-color: #EAF4FD;
      border-color: #DCDFE6;
      -webkit-box-shadow: -1px 0 0 0 #DCDFE6;
      box-shadow: -1px 0 0 0 #DCDFE6;
    }
  }

  .exam-settings-step__content__select {
    .el-input__inner {
      height: 40px;
      line-height: 40px;
      font-size: 15px;
    }
  }
</style>
<style lang="scss" scoped>
  .exam-submit-container {
    max-width: 1100px;
    background-color: #ffffff;
    padding: 28px;
    margin: 0 auto;
    position: relative;
    z-index: 99;

    .exam-content {
      &-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
      }

      .continue-btn {
        @include flex(flex-end);
        width: 174px;
        @include font(17px, #309AF2);
        cursor: pointer;

        .icon {
          @include icon(url("../../assets/arrow-blue.png"), 6px, 11px);
          margin-left: 6px;
          margin-right: 28px;
        }
      }

      &-list-view {
        width: 1044px;
        min-height: 70px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;

        .list-item {
          height: 70px;
          padding: 0 28px;
          border-bottom: 1px solid #F6F6F6;

          .list-content {
            @include flex(space-between);
            height: 70px;
            @include font(17px);

            .module {
              color: #999999;
              margin-right: 54px;
            }

            .del-btn {
              background: url("../../assets/delete.png") no-repeat center;
              background-size: 16px 16px;
              width: 60px;
              height: 34px;
              border: 1px solid #E5E5E5;
              border-radius: 17px;
              cursor: pointer;

              &:hover {
                background-color: #E5E5E5;
              }
            }
          }

          &:hover {
            background-color: #F6F6F6;
          }

          &:first-child {
            border-radius: 8px 8px 0 0;
          }
        }

        .statistics-view {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 44px;
          color: #309AF2;
          font-size: 15px;
          background-color: #F6F6F6;
          padding: 0 28px;
          border-radius: 0 0 8px 8px;
        }
      }
    }

    .exam-settings {
      margin-top: 28px;

      &-step {
        display: flex;
        justify-content: flex-start;
        min-height: 98px;

        &__head {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        &__line {
          width: 1px;
          height: 100%;
          background-color: #C8C8C8;
        }

        &__icon {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 20px;
          color: #ffffff;
          border-radius: 50%;
          background-color: #309AF2;
        }

        &__main {
          width: 984px;
          padding-bottom: 28px;
          margin-left: 20px;
        }

        &__title {
          line-height: 40px;
          color: #333333;
          font-size: 17px;
          margin-bottom: 14px;
        }

        &__content {
          &__set {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &__set-expand {
            align-items: flex-start;
            height: auto;
          }

          &__label {
            flex-shrink: 0;
            width: 90px;
            font-size: 15px;
            color: #333333;
          }

          &__label__medium {
            flex-shrink: 0;
            width: 164px;
            font-size: 15px;
            color: #333333;
          }

          &__label-expand {
            margin-top: 8px;
          }

          &__search {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            color: #333333;
          }

          &__classes {
            width: 895px;
            min-height: 98px;
            border-radius: 8px;
            border: 1px solid #E5E5E5;

            &-desc {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 44px;
              color: #333333;
              font-size: 15px;
              background: #F6F6F6;
              border-radius: 8px 8px 0 0;
              padding: 0 14px;
            }

            &-content {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              padding: 14px 0 0 14px;
            }
          }

          .classes-unify-list {
            height: 36px;
            line-height: 36px;
            border-radius: 4px;
            color: #666666;
            text-align: center;
            cursor: pointer;
            border: 1px solid #C8C8C8;
            background-color: #F6F6F6;
            padding: 0 14px;
            margin-right: 14px;
            margin-bottom: 14px;

            &:hover {
              color: #333333;
            }
          }

          .classes-unify-active {
            background-color: #EAF4FD;
            color: #309AF2;
            border-color: #309AF2;

            &:hover {
              color: #309AF2;
            }
          }

          .classes-again-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 162px;
            height: 66px;
            color: #333333;
            font-size: 15px;
            cursor: pointer;
            border-radius: 8px;
            border: 1px solid #E5E5E5;
            padding: 0 10px 0 14px;
            margin-right: 13px;
            margin-bottom: 13px;

            &:hover {
              background-color: #F6F6F6;
            }

            &__left {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
            }

            &__result {
              color: #999999;
              font-size: 13px;
              margin-top: 3px;
            }

            &__arrow-right {
              flex-shrink: 0;
              width: 4px;
              height: 8px;
              background: url("../../assets/icon-arrow-right.png") no-repeat center;
              background-size: 100% 100%;
            }
          }

          .classes-again-active {
            background-color: #EAF4FD;
            border-color: #309AF2;
          }
        }
      }
    }

    .nav-title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }

    .exam-settings-step__content__select__mark {
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-image: url("../../assets/mark.png");
      background-size: 100% 100%;
      margin-left: 10px;
    }

    .text-ellipsis2 {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .space {
      display: inline-block;
      width: 16px;
    }

    .user-column {
      background-color: #fff;
      padding: 24px 28px;
    }

    .class-list {
      @include flex(space-between, flex-start);
      margin-bottom: 16px;

      .left {
        @include flex(flex-start);
        flex-wrap: wrap;

        .class-btn {
          background-color: #F6F6F6;
          height: 28px;
          line-height: 28px;
          padding: 0 14px;
          border: 1px solid #C8C8C8;
          border-radius: 14px;
          margin-right: 14px;
          margin-bottom: 10px;
          @include font(15px, #666666, center);
          cursor: pointer;
        }

        .active {
          background-color: #EAF4FD;
          border-color: #309AF2;
          color: #309AF2;
        }
      }

      .right {
        flex-shrink: 0;
        width: 140px;
        height: 34px;
        line-height: 34px;
        border: 1px solid #309AF2;
        border-radius: 4px;
        @include font(15px, #309AF2, center);
        cursor: pointer;
      }
    }

    .teacher-table {
      border: 1px solid #E5E5E5;
      overflow: hidden;
      margin-bottom: 20px;

      .tr,
      .th {
        @include flex;
        height: 48px;
        @include font(15px, #333333, center);

        .td {
          flex-grow: 1;
          flex-shrink: 0;
          width: 12.5%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .btn {
            background-color: #fff;
            width: 70px;
            height: 32px;
            line-height: 32px;
            border-radius: 16px;
            margin: 0 auto;
            text-align: center;
            color: #309AF2;
            cursor: pointer;
          }
        }
      }

      .th {
        background-color: #F6F6F6;
        height: 44px;
        color: #666666;
      }

      .teacher-content {
        max-height: 400px;
        min-height: 216px;
        overflow: auto;
      }
    }
  }

  .footer {
    @include flex;
    max-width: 1100px;
    height: 84px;
    border-top: 1px solid #E5E5E5;
    background-color: #ffffff;
    margin: 0 auto;

    .submit-btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 250px;
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      @include font(17px, #fff, center);
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }

      &:active {
        background: #6DBBFC;
      }
    }
  }
</style>
