<template>
    <el-dialog
            :title="list.length===1?'班级学生列表':'已选学生列表'"
            class="student-dialog"
            width="1060px"
            top="50px"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div class="teacher-table">
            <ul class="th">
                <li class="td" style="width: 80px;">
                    <div class="btn" @click="allSelect">{{allSelected ? '全不选' : '全选'}}</div>
                </li>
                <li class="td">学生姓名</li>
                <li class="td">班级名称</li>
                <li class="td">班级ID</li>
                <li class="td" style="width: 90px;">年度</li>
                <li class="td" style="width: 90px;">年级</li>
                <li class="td">教师名称</li>
                <li class="td" style="width: 160px;">教师电话</li>
            </ul>
            <div class="teacher-content">
                <div style="margin-top: 50px; text-align: center;"
                     v-if="list.length===1&&list[0].studentList.length===0">
                    该班级暂无学生
                </div>
                <template v-else>
                    <div v-for="(item, classIndex) in list" :key="item.classId">
                        <ul class="tr" v-for="(student, studentIndex) in item.studentList" :key="student.id">
                            <li class="td" style="width: 80px;">
                                <el-checkbox v-model="student.selected"
                                             @change="changeStudentSelected($event, classIndex, studentIndex)"></el-checkbox>
                            </li>
                            <li class="td">{{student.realName || student.name}}</li>
                            <li class="td">{{item.className}}</li>
                            <li class="td">{{item.classNum}}</li>
                            <li class="td" style="width: 90px;">{{item.year}}</li>
                            <li class="td" style="width: 90px;">{{item.gradeName}}</li>
                            <li class="td">{{item.name}}</li>
                            <li class="td" style="width: 160px;">{{item.phone}}</li>
                        </ul>
                    </div>
                </template>
            </div>
        </div>
        <div class="footer">
            <el-button class="btn" type="primary" @click="confirmSelected">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'StudentDialog',
        props: ['dialogVisible'],
        data() {
            return {
                allSelected: true,
                list: []
            }
        },
        methods: {
            allSelect() {
                this.allSelected = !this.allSelected
                const {list} = this
                for (let i = 0, len = list.length; i < len; i += 1) {
                    const {studentList} = list[i]
                    for (let j = 0; j < studentList.length; j += 1) {
                        studentList[j].selected = this.allSelected
                    }
                }
                this.list = JSON.parse(JSON.stringify(list))
            },
            changeStudentSelected($event, classIndex, studentIndex) {
                this.$set(this.list[classIndex].studentList[studentIndex], 'selected', $event)
            },
            getList(list) {
                this.list = JSON.parse(JSON.stringify(list))
                if (list.length === 1) { // 班级学生列表
                    this.allSelected = list[0].studentList.every(item => item.selected === true)
                } else {
                    this.allSelected = true
                }
                this.$emit('handleVisible')
            },
            handleClose() {
                this.$emit('handleVisible')
            },
            confirmSelected() {
                const list = JSON.parse(JSON.stringify(this.list))
                for (let i = 0, len = list.length; i < len; i += 1) {
                    const {studentList} = list[i]
                    for (let j = 0; j < studentList.length; j += 1) {
                        if (!studentList[j].selected) {
                            studentList.splice(j, 1)
                            j -= 1
                        }
                    }
                }
                this.$emit('confirmSelected', list)
                this.list = []
            }
        }
    }
</script>

<style>
    .student-dialog .el-dialog__body {
        padding: 15px 0 0 0;
    }
</style>
<style lang="scss" scoped>
    .teacher-table {
        border: 1px solid #E5E5E5;
        margin: 0 28px;
        overflow: hidden;

        .tr,
        .th {
            @include flex;
            height: 48px;
            @include font(15px, #333333, center);

            .td {
                flex-grow: 1;
                flex-shrink: 0;
                width: 12.5%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .btn {
                    background-color: #fff;
                    width: 70px;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 16px;
                    margin: 0 auto;
                    text-align: center;
                    color: #309AF2;
                    cursor: pointer;
                }
            }
        }

        .th {
            background-color: #F6F6F6;
            height: 44px;
            color: #666666;
        }

        .teacher-content {
            max-height: 60vh;
            min-height: 20vh;
            overflow: auto;
        }
    }

    .footer {
        padding-bottom: 19px;
        border-top: 1px solid #E5E5E5;
        text-align: center;
        box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);

        .btn {
            width: 250px;
            height: 46px;
            margin-top: 19px;
            font-size: 17px;
        }
    }
</style>
